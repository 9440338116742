// <editor-fold desc="General">

@radius-default: .2rem;
@overlay-opacity: .3;
@overlay-color: rgba(0, 0, 0, calc(1 - @overlay-opacity));

@elevation-levels: 1, 2, 3, 4, 5;
@max-z-index: 15;

// </editor-fold>
// <editor-fold desc="Screen sizes">

@screen-xs: 576px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1200px;

@max-content-width: 1250px;
@content-spacing: 42px; // 3rem

// </editor-fold>
// <editor-fold desc="Breakpoints">

@min-screen-xs: ~'min-width:' @screen-xs;
@max-screen-xs: ~'max-width:' @screen-xs - 1;

@min-screen-sm: ~'min-width:' @screen-sm;
@max-screen-sm: ~'max-width:' @screen-sm - 1;

@min-screen-md: ~'min-width:' @screen-md;
@max-screen-md: ~'max-width:' @screen-md - 1;

@min-screen-lg: ~'min-width:' @screen-lg;
@max-screen-lg: ~'max-width:' @screen-lg - 1;

@breakpoints: xs, sm, md, lg;

// </editor-fold>
// <editor-fold desc="Fonts">

@font-primary: "Trebuchet MS", Arial, Helvetica, sans-serif;
@font-secondary: Georgia, "Times New Roman", serif;

// </editor-fold>
// <editor-fold desc="Colors">

@color-white: var(--color-white);
@color-black: var(--color-black);

@color-default-0: var(--color-default-0);
@color-default-1: var(--color-default-1);
@color-default-2: var(--color-default-2);
@color-default-3: var(--color-default-3);
@color-default-4: var(--color-default-4);
@color-default-5: var(--color-default-5);
@color-default-text-1: var(--color-default-text-1);
@color-default-text-2: var(--color-default-text-2);
@color-default-text-3: var(--color-default-text-3);
@color-default-text-4: var(--color-default-text-4);
@color-default-text-5: var(--color-default-text-5);
@color-danger-1: var(--color-danger-1);
@color-danger-2: var(--color-danger-2);
@color-danger-3: var(--color-danger-3);
@color-danger-4: var(--color-danger-4);
@color-danger-5: var(--color-danger-5);
@color-primary-1: var(--color-primary-1);
@color-primary-2: var(--color-primary-2);
@color-primary-3: var(--color-primary-3);
@color-primary-4: var(--color-primary-4);
@color-primary-5: var(--color-primary-5);
@color-info-1: var(--color-info-1);
@color-info-2: var(--color-info-2);
@color-info-3: var(--color-info-3);
@color-info-4: var(--color-info-4);
@color-info-5: var(--color-info-5);
@color-success-1: var(--color-success-1);
@color-success-2: var(--color-success-2);
@color-success-3: var(--color-success-3);
@color-success-4: var(--color-success-4);
@color-success-5: var(--color-success-5);
@color-warning-1: var(--color-warning-1);
@color-warning-2: var(--color-warning-2);
@color-warning-3: var(--color-warning-3);
@color-warning-4: var(--color-warning-4);
@color-warning-5: var(--color-warning-5);

@color-table-row-1: var(--color-table-row-1);
@color-table-row-2: var(--color-table-row-2);
@color-table-row-hover: var(--color-table-row-hover);

@color-input-background: var(--color-input-background);

@color-variants: danger, default, primary, info, success, warning;
@color-levels: 1, 2, 3, 4, 5;

// </editor-fold>

// <editor-fold desc="Colors Design System">
@color-primary-50: var(--color-primary-50);
@color-primary-800: var(--color-primary-800);
@color-primary-600: var(--color-primary-600);

@color-secondary-500: var(--color-secondary-500);

@color-success-100: var(--color-success-100);
@color-success-200: var(--color-success-200);
@color-success-700: var(--color-success-700);
@color-success-800: var(--color-success-800);

@color-info-700: var(--color-info-700);
@color-info-50: var(--color-info-50);

@color-danger-100: var(--color-danger-100);
@color-danger-700: var(--color-danger-700);
@color-danger-800: var(--color-danger-800);

@color-neutral-50: var(--color-neutral-50);
@color-neutral-100: var(--color-neutral-100);
@color-neutral-200: var(--color-neutral-200);
@color-neutral-800: var(--color-neutral-800);
@color-neutral-900: var(--color-neutral-900);

// </editor-fold>

// <editor-fold desc="Transition">

@transition-duration: .1s;
@transition-curve: ease-in-out;
@transition-default: @transition-duration @transition-curve;

@drawer-transition-duration: .3s;

// </editor-fold>
// <editor-fold desc="Shadows">

@shadow-1: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
@shadow-2: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
@shadow-3: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
@shadow-4: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
@shadow-5: 0px 0px 9px 0px rgba(0, 0, 0, 0.3);

@default-shadow: @shadow-2;

// </editor-fold>
// <editor-fold desc="Inputs">

@input-height-xs: 2.8rem;

@input-height-small-xs: 2.3rem;
@input-height-large-xs: 3.3rem;
@input-height-extra-large-xs: 3.5rem;

@input-height-sm: @input-height-xs - .5rem;

@input-height-small-sm: @input-height-small-xs - .5rem;
@input-height-large-sm: @input-height-large-xs - .5rem;
@input-height-extra-large-sm: @input-height-extra-large-xs - .5rem;

@input-border-width: 1px;
@input-border-color: @color-default-2;
@input-text-border-color-hover: @color-primary-3;
// </editor-fold>

// <editor-fold desc="Spacing">

@directions: {
  top: t;
  right: r;
  bottom: b;
  left: l;
};

@spacer: 1rem;
@spacer-factors: 0, .25, .5, 1, 2, 3, 4, 6, 10;

// </editor-fold>
// <editor-fold desc="Typography">

@regular-weight: var(--regular-weight);
@bold-weight: var(--bold-weight);

// Major Third ratio (mobile)
@headings-sizes-xs: 2.441, 1.953, 1.563, 1.25, 1.18, 1.18;
@headlines-sizes-xs: 4.768, 3.815, 3.052, 2.441;

// Perfect Fourth ratio (desktop)
@headings-sizes-sm: 3.157, 2.369, 1.777, 1.333, 1.24, 1.24;
@headlines-sizes-sm: 7.478, 5.61, 4.209, 3.157;

// </editor-fold>