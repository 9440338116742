@import '../../_variables';
@import '../../element/_footer';
@import '../../element/_icons-mixins';
@import '../../element/_modal-promo';

//<editor-fold desc="FRAMES">
.frame {
  background-color: @color-default-1;
  border-radius: @radius-default;
  padding: 1rem;
}

.frame-outline {
  background-color: transparent;
  border-radius: @radius-default;
  padding: 1rem;
  border: 2px solid @color-default-2;
}

//</editor-fold>
//<editor-fold desc="Buy Button">
.buyButton {
  border-radius: 1rem;
}

//</editor-fold>

//<editor-fold desc="maisuni">
.header {
  display: flex;
  justify-content: center;
  height: 70px;
  background-color: #ededed;
  color: #000;
}

.wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76%;
  height: 100%;
}

.menu-nav {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.menu-mobile {
  display: flex;
  position: absolute;
  top: calc(47px + 31%);
  width: 100%;
  background: #ededed;
  left: 0;
  height: auto;
  flex-direction: column;
  gap: 2rem;
  margin: 0px;
  padding: 35px;
  animation: fadeIn 0.3s ease-in-out;

  @media (@min-screen-sm) {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.menu-nav li {
  list-style: none;
  font-size: 16.5px;
}

.menu-nav li a {
  color: #000;
}

.menu-nav li:hover a {
  text-decoration: none;
}

.menu-mobile li {
  list-style: none;
  font-size: 16.5px;
}

.menu-mobile li a {
  color: #000;
}

.menu-mobile li a:hover {
  text-decoration: none;
}

.one-card {
  margin: 20px 15px 20px 15px;
  height: 360px;
  background-color: #e8e8e8;
  padding: 20px;
}

.carousel {
  background-color: #f5d951;
}

.ft-sec {
  margin-top: calc(70px + 4%);
}

.one-card {
  margin: 20px 15px 20px 15px;
  height: 360px;
  background-color: #e8e8e8;
  padding: 20px;
}

#maisuni-home-vue .carousel {
  background-color: #f5d951;
}

.img-escola {
  width: 80%;
}

#maisuni-home-vue .form {
  background-color: #6aa5d9;
  padding: 5% 20% 5% 20%;
}

#maisuni-home-vue .form-input {
  width: 100%;
}

.btn-width {
  width: 100%;
  background-color: #1a1d1a !important;
  color: white !important;
}

.video-player {
  height: 800px;
}

@media (max-width: 576px) {
  #footer .footer-advantages-items > .d-flex {
    margin: 0 auto;
  }

  #footer #footer-pr {
    margin-top: -10px !important;
  }

  #footer .software-footer {
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .video-player {
    height: 400px;
  }
}

@media (max-width: 320px) {
  .video-player {
    height: 150px;
  }
}

.space {
  margin-top: 5%;
  justify-content: center;
}

//</editor-fold>

// <editor-fold desc="ICONS">

// social
.icon-instagram();
.icon-facebook();
.icon-twitter();
.icon-youtube();
.icon-tiktok();
.icon-pinterest();
.icon-spotify();
.icon-user();
.icon-wallet();
.icon-email();

// history
.icon-register();

// </editor-fold>

// <editor-fold desc="ALERTS">

[class*='alert-'] {
  font-size: 1.2rem;

  // todo remover
  button.close {
    color: white;
  }

  .icon-cr.icon-close {
    display: block;
    float: right;
    height: 1.8rem;
    width: 1.8rem;
    opacity: .7;
    cursor: pointer;
  }
}

// </editor-fold>

// <editor-fold desc="ANIMATIONS">
@keyframes openDrawer {
  0% {
    display: block;
    transform: translateX(-100%);
    box-shadow: none;
  }
  100% {
    display: block;
    transform: translateX(0);
    box-shadow: @default-shadow;
  }
}

@keyframes closeDrawer {
  0% {
    display: block;
    transform: translateX(0);
    box-shadow: @default-shadow;
  }
  100% {
    display: block;
    transform: translateX(-100%);
    box-shadow: none;
  }
}

@keyframes showOverlay {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: .3;
  }
}

@keyframes hideOverlay {
  0% {
    display: block;
    opacity: .3;
  }
  100% {
    display: block;
    opacity: 0;
  }
}

// </editor-fold>

//<editor-fold desc="DIVIDERS">

.spaced-dots {
  background-image: linear-gradient(to right, @color-default-4 20%, transparent 0%);
  background-position: bottom;
  background-size: 5px 1px;
  background-repeat: repeat-x;
  height: 3px;
}

//</editor-fold>


.slide-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.slide-item {
  flex: 0 0 calc(25% - 20px);
  margin: 10px;
  float: left;
  text-align: center;
}

.slide-item img {
  width: 100%;
  object-fit: cover;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0, 0);
}

.bg-slide {
  background: #ededed;
}

@media (max-width: 767px) {
  .slide-item {
    flex: 0 0 calc(33% - 20px) !important;
    //margin: 0.5rem;
  }

  .slide-item:last-child {
    margin-right: 0;
  }

  .arrow-slide {
    width: 25px;
  }
}