@import '../_variables';
@import '_chat';

html {
  background-color: @color-default-2;
}

body {
  background-color: white;
}

#footer {
  font-weight: 300;
  position: relative;
  margin-top: 2rem;
  background-color: @color-default-5;

  #footer-main-content {
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    text-align: center;
  }

  #footer-seo-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 300;

    a {
      text-decoration: underline;
    }
  }

  #footer-achievements {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > *, img {
      height: 6rem;
    }

    > :nth-child(n+2) {
      margin-top: 1rem;
    }

    > :not(#footer-change-world) img {
      padding: .5rem 0;
    }

    #footer-humanizadas img {
      padding: 0;
    }

    .cr-popover-content {
      min-width: 300px;
    }
  }

  @media (@min-screen-xs) {
    #footer-achievements {
      > * {
        margin-right: 2rem;

        &:nth-child(n+2) {
          margin-top: 0;
        }
      }
    }
  }

  #footer-main-content > :not(:last-child) {
    margin-top: 2rem;
  }

  #footer-main-content > :last-child > div {
    margin-top: 2rem;
  }

  #footer-logo {
    width: 9rem;
    height: 3rem;
    display: block;
    mask-position: center;
    margin: 0 auto;
  }

  #footer-social {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    justify-content: center;

    a {
      height: 2rem;
      width: 2rem;
      background-position: left;
      margin: 0 .75rem 1rem;

      @media (hover: hover) and (pointer: fine) {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .footer-payment-f {
    margin-top: 2rem;
  }

  #footer-contact {
    max-width: 16rem;
    margin-left: auto;
    margin-right: auto;
  }

  .footer-advantages-items > .d-flex {
    flex-basis: 50%;
    flex-direction: column;
    align-items: center;

    > .icon-cr {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      margin-right: .5rem;
    }
  }

  #footer-payments {
    align-items: center;

    .footer-payment {
      max-height: 35px;
    }
  }

  #footer-certificates img {
    height: 35px;
  }

  #footer-certificates, #footer-payments {
    position: relative;
    top: -.5rem;
    left: -.5rem;
    margin-bottom: -1rem;
    width: calc(100% + 1rem);

    > * {
      margin: .5rem;
      flex: 0 0 auto;
    }
  }

  ul.inline-list {
    list-style-type: none;
    padding-left: 0;

    li {
      display: inline;
    }

    li:not(:first-child) {
      position: relative;
      padding-left: 1rem;

      &:before {
        content: '-';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1rem;
        text-align: center;
        transform: translateY(-.25em);
      }
    }
  }

  .seo-items-group {
    margin-top: .5rem;
  }

  @media (@min-screen-xs) {

    #footer-main-content {
      align-items: normal;
      flex-direction: row;
      padding-top: 2rem;
      padding-bottom: 1.5rem;
      text-align: left;
    }

    #footer-main-content > * {
      flex: 0 0 50%;
    }

    #footer-logo {
      background-position: left;
      position: relative;
      margin-left: 0;
    }


    #footer-social {
      justify-content: start;

      a {
        margin-left: 0;
        margin-right: 1rem;
      }
    }

    #footer-contact {
      margin: 0;
    }

    .footer-advantages-items > .d-flex {
      flex-direction: row;
      align-items: normal;
    }
  }

  @media (@min-screen-sm) {
    .seo-items-group {
      display: flex;
      align-items: baseline;

      > :first-child {
        width: 16rem;
      }
    }

    .footer-advantages-items {
      max-width: 15rem;

      > .d-flex {
        flex-basis: 40%;

        &:not(:first-child) {
          margin-top: 2rem;
        }
      }
    }
  }

  @media (@min-screen-lg) {
    #footer-main-content {
      flex-wrap: nowrap;
    }

    #footer-main-content > * {
      flex: 0 0 25%;
    }
  }

  @media (@max-screen-xs) {
    #footer-newsletter {
      input, select, .btn {
        margin-bottom: .5rem;
        width: 100%;

        &:last-child {
          margin-bottom: 0
        }
      }
    }

    #footer-payments, #footer-certificates {
      justify-content: center;
    }

    .footer-advantages-items {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (@min-screen-xs) {
    #footer-newsletter {
      display: flex;
      align-items: center;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > input {
        margin-right: .5rem;
      }

      > .btn {
        margin-left: .5rem;
      }

      select, input, textarea {
        &:not([type=submit]):not([type=button]):not([type=file]) {
          width: auto;
        }
      }

      > input:not(.btn):not(label) {
        flex: 1 1 auto;
      }
    }
  }
}

@media (min-width: 992px) {
  .a2hs {
    display: none;
  }
}

@media (max-width: 991px) {
  .a2hs {
    display: block;

    .banner {
      display: none;
      color: white;
      background-color: @color-default-4;
      margin: 0;
      position: fixed;
      bottom: 0;
      width: 100vw;
      height: 50px;
      z-index: 10000;

      .accept {
        width: 86%;
        height: 100%;
        float: left;
        padding: 11px 0 0 3px;
      }

      .deny {
        width: 14%;
        height: 100%;
        float: right;
        padding: 11px 3px 0 0;

        .icon-cr {
          width: 30px;
          height: 30px;
          float: right;
        }
      }

      .icone-logo {
        width: 32px;
        height: 32px;
        margin-left: 6px;
        float: left;
      }

      .message {
        float: left;
        margin: 4px 0 5px 8px;
        font-size: 14px;
      }
    }
  }
}
